import React from 'react';
import STYLES from 'src/utils/stylesConstants';
import DetailedCardWithBackground from 'components/DetailedCardWithBackground';
import DefaultCard from 'components/DefaultCard';
import QuoteCard from 'components/QuoteCard';
import Card from 'features/estates/card';
import styles from './cards.module.scss';

const ListOfCards = ({ cards = [], title = '' }) => {
  const renderCardList = cards => {
    switch (cards[0]?.style) {
      case STYLES.listOfCards.DetailedCardWithBg:
        return <DetailedCardWithBackground cards={cards} />;
      case STYLES.listOfCards.CardWithBgImage:
        return (
          <div className={styles.cardWithBgWrapper}>
            <div className={styles.cardWithBg}>
              <div>
                {cards?.length &&
                  cards.map(
                    (
                      {
                        title,
                        description: {
                          childMarkdownRemark: { html },
                        },
                        background,
                        textAlign,
                      },
                      index
                    ) => (
                      <Card
                        key={index}
                        title={title}
                        description={html}
                        image={background}
                        textAlign={textAlign}
                      />
                    )
                  )}
              </div>
            </div>
          </div>
        );
      case STYLES.listOfCards.Default:
        const ways =
          cards?.length &&
          cards.map(
            ({
              title,
              description: {
                childMarkdownRemark: { html },
              },
            }) => ({ title, description: html })
          );
        return <DefaultCard title={title} ways={ways} />;

      case STYLES.listOfCards.BasicQuote:
        return (
          cards?.length &&
          cards.map(({ title, textAlign, position }, index) => (
            <QuoteCard
              quote={title}
              key={index}
              textAlign={textAlign}
              position={position}
            />
          ))
        );
      default:
        return '';
    }
  };

  return <>{renderCardList(cards)}</>;
};

export default ListOfCards;
