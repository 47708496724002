import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import HorizontalCarousel from 'components/HorizontalCarousel';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import { combineCloudinaryWithImages } from 'utils/image';
import { GET_AUCTION_FEATURED_LOTS } from 'services/graphql/queries/auctions/AuctionFeaturedLots';

const AuctionFeaturedLots = ({ auctionId, className = '' }) => {
  const { loading, error, data } = useQuery(GET_AUCTION_FEATURED_LOTS, {
    variables: { auctionId: auctionId },
  });

  const [featuredLots, setFeaturedLots] = useState([]);

  useEffect(() => {
    if (data?.auctionFeaturedLots?.length > 0) {
      const { auctionFeaturedLots } = data;
      setFeaturedLots(
        auctionFeaturedLots
          .map(vehicle => ({
            ...vehicle,
            cloudinaryImages: [vehicle.featuredImage],
          }))
          .map(vehicle => {
            return {
              ...vehicle,
              featuredImage: combineCloudinaryWithImages(vehicle)[0] ?? null,
            };
          })
      );
    }
  }, [data]);

  return (
    <>
      {error && <Alert color="danger" msg={error.message} />}
      {loading && <Spinner />}
      <HorizontalCarousel className={className} items={featuredLots} />
    </>
  );
};

export default AuctionFeaturedLots;
