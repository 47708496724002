import React from 'react';
import slugify from 'slugify';
import { Link, navigate } from 'gatsby';
import styles from './SideMenu.module.scss';
import Button from 'components/Button';
import BeFirstToKnowIcon from '../../../static/svg/BeFirstToKnowIcon';

const SideMenu = ({ sideMenuItems, headline, isTop }) => {
  return (
    <div
      className={`${styles.sideMenuWrapper} ${
        isTop ? styles.richedTop : styles.richedTopReverse
      }`}
    >
      <h2 className="mb-4 mt-0">{headline}</h2>
      {sideMenuItems?.length &&
        sideMenuItems.map((item, key) => (
          <Link
            to={`#${slugify(item, { strict: true, lower: true })}`}
            key={key}
            className={styles.menuItems}
          >
            {item}
          </Link>
        ))}
      <Button
        type={`${styles.menuIcon} app-primary-button`}
        handleClick={() => navigate(`/contact-us?form-terms`)}
      >
        <BeFirstToKnowIcon />
        Contact Us
      </Button>
    </div>
  );
};

export default SideMenu;
