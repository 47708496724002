import React from 'react';
import PropTypes from 'prop-types';
import styles from './waysToBid.module.scss';

const DefaultCard = ({ title, ways }) => (
  <div className={`${styles.ways} `}>
    <h2>{title}</h2>
    <div className={styles.waysText}>
      {ways &&
        ways.map((way, index) => (
          <div
            key={`waysToBid${index}`}
            className={
              way.textAlign ? `text-${way.textAlign.toLowerCase()}` : ''
            }
          >
            <h3>{way.title}</h3>
            <section
              dangerouslySetInnerHTML={{ __html: way.description }}
            ></section>
          </div>
        ))}
    </div>
  </div>
);

DefaultCard.propTypes = {
  title: PropTypes.string,
  ways: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default DefaultCard;
