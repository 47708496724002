import { gql } from '@apollo/client';

export const GET_AUCTION_FEATURED_LOTS = gql`
  query GetAuctionFeaturedLots($auctionId: String) {
    auctionFeaturedLots(auctionId: $auctionId) {
      featured
      objectID
      auctionType
      auctionName
      featuredImage {
        public_id
        width
        height
        context {
          custom {
            alt
          }
        }
      }
      model
      make
      modelYear
      slug
      itemType
      title
      auctionSlug
      auctionEndDate
      upcomingAuctionEndDate
      auctionStartDate
    }
  }
`;
