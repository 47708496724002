import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import styles from './card.module.scss';

const Card = ({ title, description, image, textAlign }) => (
  <BackgroundImage fluid={image?.fluid}>
    <div
      className={`${styles.cardStyle} ${
        textAlign === 'Center' ? 'text-center' : ''
      }`}
    >
      <div>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
      <div className={styles.overlay}></div>
    </div>
  </BackgroundImage>
);

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  image: PropTypes.string,
};

export default Card;
