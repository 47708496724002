import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import { getAlgoliaItemTitle } from 'shared/helpers/vehicles';
import { VEHICLE_PATH, AUCTION_PATH } from 'utils/pathsConstants';
import './horizontalCarousel.scss';

const carouselSettings = {
  infinite: true,
  speed: 500,
  variableWidth: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
};

const mobileCarouselSettings = {
  ...carouselSettings,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const desktopCarouselSettings = {
  ...carouselSettings,
  slidesToScroll: 1,
};

const CarouselItem = ({
  item: {
    featuredImage,
    auctionName,
    auctionSlug,
    make,
    model,
    modelYear,
    slug,
    title,
    itemType,
  },
  index,
  minHeight,
}) => {
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  return (
    <div key={index} id="my-component" className="carousel-item-wrapper ">
      <div
        style={{ 'pointer-events': 'none' }}
        className="carousel-item-overlay"
      ></div>
      {featuredImage && (
        <GatsbyImage
          image={featuredImage.fluid}
          title={featuredImage.title}
          minHeight={minHeight}
          className={'img'}
          alt={featuredImage.alt}
        />
      )}
      <div className="sliderText">
        <Link
          className="app-primary-button register-online-btn"
          to={auctionSlug ? AUCTION_PATH(auctionSlug) : '#'}
        >
          {auctionName?.toUpperCase()}
        </Link>
        <h1 className="vehicle-label">
          <Link className="cursor-pointer" to={slug ? VEHICLE_PATH(slug) : '#'}>
            {getAlgoliaItemTitle({
              itemType,
              model,
              modelYear,
              make,
              title,
            }).toUpperCase()}
          </Link>
        </h1>
      </div>
    </div>
  );
};

const HorizontalCarousel = ({ items = [], title, className = '' }) => (
  <div className={`horizontalCarousel ${className}`}>
    {!!items.length && (
      <div>
        <h2>{title}</h2>
        <div className="featuredCarouselMobile">
          <Slider {...mobileCarouselSettings}>
            {items.map((item, index) => (
              <CarouselItem item={item} index={index} key={index} />
            ))}
          </Slider>
        </div>

        <div className="featuredCarouselDesktop">
          <Slider {...desktopCarouselSettings}>
            {items.map((item, index) => (
              <CarouselItem
                item={item}
                index={index}
                key={index}
                minHeight={400}
              />
            ))}
          </Slider>
        </div>
      </div>
    )}
  </div>
);

export default HorizontalCarousel;
