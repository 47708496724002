import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styles from './ScrollToTop.module.scss';

const ScrollToTop = () => {
  const [isScrollDown, setIsScrollDown] = useState(false);

  const scrollToTop = () => {
    const isBrowser = typeof window !== `undefined`;
    if (isBrowser) window.scrollTo(0, 0);
  };

  useScrollPosition(
    ({ currPos }) => {
      const isTop = currPos.y < -150;
      if (isTop !== isScrollDown) setIsScrollDown(isTop);
    },
    [isScrollDown]
  );

  return (
    <div
      className={`justify-content-end ${styles.scrollToTopWrapper} ${
        isScrollDown ? styles.show : styles.hide
      }`}
    >
      <button onClick={scrollToTop} className={styles.scrollToTopBtn}>
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </div>
  );
};

export default ScrollToTop;
