import React from 'react';
import styles from './QuoteCard.module.scss';

const QuoteCard = ({ quote, textAlign, position }) => {
  return (
    <div className={styles.QuoteCardWrapper}>
      <div
        className={`${styles.squareBox} ${
          position === 'Left' ? styles.floatLeft : styles.floatRight
        } ${`text-${textAlign.toLowerCase()}`}`}
      >
        <h3>{quote}</h3>
      </div>
    </div>
  );
};

export default QuoteCard;
