import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SECTIONS from 'src/utils/sectionConstants';
import YouTubePlayer from 'components/YoutubePlayer';
import ListOfCards from 'features/listOfCards';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import { generateFluidImage, combineCloudinaryWithImages } from 'utils/image';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import { externalLinkPattern } from 'shared/helpers/patterns';
import Link from 'components/Link/Link';
import { Link as GatsbyLink } from 'gatsby';
import SideMenu from './SideMenu';
import SideMenuMobile from './SideMenuMobile';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useMediaQuery } from 'react-responsive';
import ScrollToTop from 'components/ScrollToTop';
import Carousel from 'components/Carousel';
import AuctionFeaturedLots from 'features/AuctionFeaturedLots';
import styles from './RichText.module.scss';
import FirstToKnow from 'components/FirstToKnowForm';
import { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { SEND_CONSIGNMENT_EMAIL } from 'services/graphql/queries/consigmnet';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import { SEND_TERMS_AND_CONDITION_EMAIL } from 'services/graphql/queries/vehicles-catalogue';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { INQUIRY_EMAIL_TITLE } from 'utils/inquiryEmailTypes';

const tooltipDefaultValue = 'Copy to clipboard';
const tooltipCopiedValue = 'Copied to clipboard';

const RichText = ({ json, headline, showSideIndex = false, location }) => {
  // const { isAuthenticated } = useAuth0();
  // const { loading, data } = useAuthenticatedQuery(GET_PROFILE_DATA, {
  //   skip: !isAuthenticated,
  // });
  const [success, setSuccess] = useState(false);
  const [
    sendEmail,
    { error: emailError, loading: isSendingEmail },
  ] = useMutation(SEND_TERMS_AND_CONDITION_EMAIL);
  const sendEmailError = emailError?.message;
  const [isForm, setIsForm] = useState(true);
  const [sideMenuItems, setSideMenuItems] = useState([]);
  const myRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 488 });

  const [tooltipText, setTooltipText] = useState(tooltipDefaultValue);
  const [allTooltipTargets, setAllTooltipTargets] = useState({});
  const scrollToContactUs = () => myRef.current.scrollIntoView();
  const tooltipToggle = targetName => {
    if (!allTooltipTargets[targetName]) {
      setAllTooltipTargets({
        ...allTooltipTargets,
        [targetName]: true,
      });
    } else {
      setAllTooltipTargets({
        ...allTooltipTargets,
        [targetName]: false,
      });
    }
  };

  const isToolTipOpen = targetName => {
    return allTooltipTargets[targetName]
      ? allTooltipTargets[targetName]
      : false;
  };
  const submitForm = async values => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: 'contact-us-submit-form',
      });
    const title = INQUIRY_EMAIL_TITLE.ASK_QUESTION_TERMS;
    const fields = {
      title: 'Terms and Conditions Question Request',
      ...values,
    };
    delete fields.confirmEmail;
    await sendEmail({
      variables: {
        fields,
      },
    }).then(res => {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  };

  const copyText = slug =>
    navigator.clipboard
      .writeText(`${window.location.origin}${window.location.pathname}#${slug}`)
      .then(() => {
        setTooltipText(tooltipCopiedValue);
        setTimeout(() => {
          setTooltipText(tooltipDefaultValue);
        }, 1000);
      });

  useEffect(() => {
    const isBrowser = typeof window !== undefined;
    if (isBrowser) {
      const { hash } = window.location;
      if (!hash) return;
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(hash);
        const offset = anchor.getBoundingClientRect().top + window.scrollY;
        window.scroll({ top: offset, left: 0 });
      });
    }
  }, [location?.hash]);

  // Check on "embedded-entry-block" to render the correct component
  const renderComponent = node => {
    const { contentful_id } = node.data.target.sys.contentType.sys;
    const data = node.data.target.fields;
    switch (contentful_id) {
      case SECTIONS.webVideoMedia:
        const videoUrl = data?.videoUrl['en-US'];
        return (
          <div className={styles.videoWrapper}>
            <YouTubePlayer url={videoUrl} />
          </div>
        );
      case SECTIONS.videoLinkWrapper:
        const url = data?.youtubeUrl?.['en-US'];
        return (
          <div className={styles.videoWrapper}>
            <YouTubePlayer url={url} />
          </div>
        );

      case SECTIONS.webSectionWrapper:
        const subType =
          data.component['en-US'].sys.contentType.sys.contentful_id;
        const subData = data.component['en-US'].fields;
        switch (subType) {
          case SECTIONS.webCard:
            const card = {
              style: subData.style['en-US'],
              title: subData.title['en-US'],
              textAlign: subData.textAlign['en-US'],
              position: data.position['en-US'],
              description: {
                childMarkdownRemark: {
                  html: subData.description ? subData.description['en-US'] : '',
                },
              },
            };
            return (
              <div>
                <ListOfCards cards={[card]} />
              </div>
            );
          default:
            return '';
        }

      case SECTIONS.imagesWrapper:
        const images = combineCloudinaryWithImages(
          {
            cloudinaryImages1: data?.images1?.['en-US'] ?? [],
            cloudinaryImages2: data?.images2?.['en-US'] ?? [],
          },
          1800
        );
        return (
          <div className={styles.carouselContainer}>
            <Carousel
              size="lg"
              fluidImages={images}
              title={data?.title['en-US'] ?? ''}
            />
          </div>
        );
      case SECTIONS.auction:
        const auctionId = node?.data?.target?.sys?.contentful_id;
        return (
          <div className={styles.carouselContainer}>
            <AuctionFeaturedLots
              className={styles.horizontalCarouselRichText}
              auctionId={auctionId}
            />
          </div>
        );

      default:
        return '';
    }
  };

  const renderAssetComponent = node => {
    const file = node?.data?.target?.fields?.file['en-US'];
    if (file?.contentType.startsWith('image')) {
      const data = file?.url;
      const width = file?.details?.image?.width;
      const height = file?.details?.image?.height;
      return (
        <div className={styles.imageWrapper}>
          <GatsbyImage
            image={generateFluidImage(1000, data, 88, width, height)}
            className={styles.img}
            alt={
              node?.data?.target?.fields?.description &&
              node?.data?.target?.fields?.description['en-US']
            }
          />
        </div>
      );
    }
  };

  const renderHeadlinesLinks = slug => {
    return (
      <>
        <FontAwesomeIcon
          className={`${styles.linkingIcon} mr-2`}
          icon={faLink}
          id={`link-icon_${slug}`}
          onClick={() => copyText(slug)}
        />
        <Tooltip
          placement="bottom"
          isOpen={isToolTipOpen(`link-icon_${slug}`)}
          target={`link-icon_${slug}`}
          toggle={() => tooltipToggle(`link-icon_${slug}`)}
        >
          {tooltipText}
        </Tooltip>
      </>
    );
  };

  useEffect(() => {
    if (json) {
      let menuItems = [];
      json?.content?.length &&
        json.content.forEach(element => {
          if (element.nodeType === BLOCKS.HEADING_2) {
            menuItems.push(element.content?.[0].value);
          }
        });
      menuItems.length > 0 &&
        setSideMenuItems(
          _.uniq(menuItems).map(item =>
            item.endsWith(':') ? item.slice(0, -1) : item
          )
        );
    }
  }, [json]);

  // Options configuration to render custom components for each block type
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return renderComponent(node);
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return renderAssetComponent(node);
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h1 id={slug} className={styles.headlines}>
            <GatsbyLink to={`#${slug}`}>
              {renderHeadlinesLinks(slug)}
              {children}
            </GatsbyLink>
          </h1>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h2 id={slug} className={styles.headlines}>
            <GatsbyLink to={`#${slug}`}>
              {renderHeadlinesLinks(slug)}
              {children}
            </GatsbyLink>
          </h2>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
        });
        return (
          <h3 id={slug} className={styles.headlines}>
            <GatsbyLink to={`#${slug}`}>
              {renderHeadlinesLinks(slug)}
              {children}
            </GatsbyLink>
          </h3>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h4 id={slug} className={styles.headlines}>
            <GatsbyLink to={`#${slug}`}>
              {renderHeadlinesLinks(slug)}
              {children}
            </GatsbyLink>
          </h4>
        );
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h5 id={slug} className={styles.headlines}>
            <GatsbyLink to={`#${slug}`}>
              {renderHeadlinesLinks(slug)}
              {children}
            </GatsbyLink>
          </h5>
        );
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h6 id={slug} className={styles.headlines}>
            <GatsbyLink to={`#${slug}`}>
              {renderHeadlinesLinks(slug)}
              {children}
            </GatsbyLink>
          </h6>
        );
      },
      [INLINES.HYPERLINK]: node => {
        return (
          <Link
            to={node?.data?.uri}
            target={
              externalLinkPattern.test(node?.data?.uri) ? '_blank' : '_self'
            }
          >
            {node?.content[0]?.value}
          </Link>
        );
      },
    },
  };

  const [scrollPosition, setScrollPosition] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      const isTop = currPos.y < -60;
      if (isTop !== scrollPosition) setScrollPosition(isTop);
    },
    [scrollPosition]
  );
  return (
    <>
      <div className={styles.richTextWrapper}>
        <div className={styles.richTextContent}>
          {isSendingEmail && <Spinner />}
          {sendEmailError && <Alert color="danger" msg={sendEmailError} />}
          {success && (
            <Alert color="success" msg="Your Question was sent successfully " />
          )}
          {showSideIndex && sideMenuItems?.length > 0 ? (
            <div className={styles.mainLayout}>
              <div>
                {!isMobile ? (
                  <SideMenu
                    sideMenuItems={sideMenuItems}
                    headline={headline}
                    isTop={scrollPosition}
                  />
                ) : (
                  <SideMenuMobile
                    sideMenuItems={sideMenuItems.map((item, index) => ({
                      id: index,
                      value: item,
                      label: item,
                    }))}
                  />
                )}
              </div>

              <div>
                {headline && <h1 className={styles.pageTitle}>{headline}</h1>}
                {documentToReactComponents(json, options)}
                <ScrollToTop />
              </div>
            </div>
          ) : (
            <>
              {headline && <h1 className={styles.pageTitle}>{headline}</h1>}
              {documentToReactComponents(json, options)}
            </>
          )}
        </div>
        <div ref={myRef} className={styles.askForm}>
          <div className={styles.innerForm}>
            <div className={styles.sectionText}>
              <h2>Still have questions? Contact us!</h2>
              <p>Fill the form to get in touch with our specialists.</p>
            </div>
            <FirstToKnow
              isForm={isForm}
              isRichText={true}
              class={styles.form}
              submit={submitForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RichText;
