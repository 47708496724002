import React from 'react';
import Select from 'components/form-elements/Select';
import { Formik, Form } from 'formik';
import { Link } from 'gatsby';
import slugify from 'slugify';
import styles from './SideMenu.module.scss';

const SideMenuMobile = ({ sideMenuItems }) => {
  const CustomOptionComponent = ({ innerProps, isSelected, label }) => (
    <Link
      {...innerProps}
      className={`item ${isSelected ? 'activeItem' : ''}`}
      to={`#${slugify(label, { strict: true, lower: true })}`}
    >
      {label}
    </Link>
  );
  return (
    <Formik
      initialValues={{
        headline: null,
      }}
    >
      {props => {
        const { values, setFieldValue } = props;
        return (
          <Form>
            <div className={`field ${styles.sideMenuMobileWrapper}`}>
              <Select
                name="headline"
                data={sideMenuItems}
                placeholder="Topics"
                onChangeHandler={() => setFieldValue(values.headline)}
                CustomOptionComponent={CustomOptionComponent}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SideMenuMobile;
